import React from "react"
import SectionHeader from "../../components/UI/elements/SectionHeader"
import Layout from "../../components/UI/layout"

const Construction = () => {
  return (
    <Layout>
      <SectionHeader headerText="Portfolio Construction" />
      <h2>The biggest question of all</h2>
      <p>
        Constructing a portfolio can be extremely hard and exceptionally easy.
        In the end, it's not a science like biology or math but rather a
        philosophy. The easiest approach is naive diversification. If you want
        to invest in <i>n</i> assets, each asset receives the weight 1/<i>n</i>.
      </p>
    </Layout>
  )
}

export default Construction
